<template>
	<v-layout>
		<Dialog :dialog="personDialog" :dialogWidth="1000">
			<template v-slot:title>
				<span v-if="doUpdate">Update</span>
				<span v-else>Add</span>
				<span v-if="isTenant">&nbsp;Tenant</span><span v-else>&nbsp;Contact Person</span>
			</template>
			<template v-slot:body>
				<v-form
					ref="personForm"
					v-model.trim="formValid"
					lazy-validation
					v-on:submit.stop.prevent="appendContactPerson"
				>
					<v-row class="mx-3">
						<v-col md="4" class="mb-3">
							<label class="font-size-14 font-weight-500 required" for="y-salutation">Salutation</label>
							<SelectInput
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								:items.sync="listTitle"
								id="salutation"
								hide-top-margin
								class="mt-1"
								placeholder="Title"
								v-model.trim="contactPerson.salutation"
							></SelectInput>
						</v-col>
						<v-col md="4" class="mb-3">
							<label class="font-size-14 font-weight-500 required" for="y-first_name">First Name</label>
							<TextInput
								hide-details
								placeholder="First Name"
								hide-top-margin
								class="mt-1"
								v-model.trim="contactPerson.first_name"
								:rules="[
									vrules.required(contactPerson.first_name, 'first name'),
									vrules.minLength(contactPerson.first_name, 'first name', 2),
									vrules.maxLength(contactPerson.first_name, 'first name', 100),
								]"
								:class="{
									required: !contactPerson.first_name,
								}"
								v-on:keyup="appendDisplayName()"
								:disabled="pageLoading"
								:loading="pageLoading"
							></TextInput>
						</v-col>
						<v-col md="4" class="mb-3">
							<label
								class="font-size-14 font-weight-500"
								:class="{ required: value && value.length < 1 }"
								for="y-last_name"
								>Last Name</label
							>
							<TextInput
								hide-details
								placeholder="Last Name"
								hide-top-margin
								class="mt-1"
								v-model.trim="contactPerson.last_name"
								:rules="[
									value && value.length < 1 ? vrules.required(contactPerson.last_name, 'Last name') : true,
									vrules.minLength(contactPerson.last_name, 'Last name', 2),
									vrules.maxLength(contactPerson.last_name, 'Last name', 100),
								]"
								:class="{
									required: value && value.length < 1 ? !contactPerson.last_name : false,
								}"
								v-on:keyup="appendDisplayName()"
								:disabled="pageLoading"
								:loading="pageLoading"
							></TextInput>
						</v-col>
						<v-col md="4" class="mb-3">
							<label class="font-size-14 font-weight-500 required" for="y-display_name"
								>Display Name</label
							>
							<TextInput
								v-if="false"
								hide-details
								placeholder="Display Name"
								hide-top-margin
								class="mt-1"
								v-model.trim="contactPerson.display_name"
								:rules="[
									vrules.required(contactPerson.display_name, 'display name'),
									vrules.minLength(contactPerson.display_name, 'display name', 2),
									vrules.maxLength(contactPerson.display_name, 'display name', 100),
								]"
								:class="{
									required: !contactPerson.display_name,
								}"
								:disabled="pageLoading"
								:loading="pageLoading"
							></TextInput>
							<TextValidateInput
								hide-details
								hide-top-margin
								:disabled="pageLoading"
								:loading="pageLoading"
								placeholder="Display Name"
								id="display-name"
								:rules="[
									vrules.required(contactPerson.display_name, 'Display Name'),
									vrules.minLength(contactPerson.display_name, 'display name', 2),
									vrules.maxLength(contactPerson.display_name, 'display name', 100),
								]"
								:class="{
									required: !contactPerson.display_name,
								}"
								:validationField="{
									url_type: type,
									filter_type: 'contact_person',
									field: 'display_name',
								}"
								:current-id="contactPerson.id"
								show-dropdown
								v-model="contactPerson.display_name"
							></TextValidateInput>
						</v-col>
						<v-col md="4" class="mb-3">
							<label class="font-size-14 font-weight-500 required" for="y-mobile">Phone Number</label>
							<PhoneInput
								required
								hide-details
								hide-top-margin
								class-name="mt-1"
								:disabled="pageLoading"
								:loading="pageLoading"
								id="contactperson-fax"
								placeholder="Phone Number"
								v-model="contactPerson.mobile"
								:validation-field="{
									url_type: type,
									filter_type: 'contact_person',
									field: 'phone_number',
								}"
								:current-id="contactPerson.id"
							>
							</PhoneInput>
							<PhoneTemplate
								v-if="false"
								hide-details
								hide-top-margin
								class-name="mt-1"
								:disabled="pageLoading"
								:loading="pageLoading"
								:rules="[
									vrules.required(contactPerson.mobile, 'Mobile'),
									vrules.phoneNumber(contactPerson.mobile, 'Mobile', 12),
								]"
								:class="{
									required: !contactPerson.mobile,
								}"
								id="contactperson-fax"
								placeholder="Phone Number"
								v-model="contactPerson.mobile"
							>
							</PhoneTemplate>
						</v-col>
						<v-col md="4" class="mb-3">
							<label class="font-size-14 font-weight-500" for="y-mobile">DID</label>
							<PhoneInput
								hide-top-margin
								class-name="mt-1"
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								placeholder="DID"
								v-model="contactPerson.did"
								:validation-field="{
									url_type: type,
									filter_type: 'contact_person',
									field: 'did',
								}"
								:current-id="contactPerson.id"
							>
							</PhoneInput>
						</v-col>
						<v-col md="4" class="mb-3">
							<label class="font-size-14 font-weight-500 required" for="y-email">Email</label>
							<EmailInput
								hide-details
								hide-top-margin
								class="mt-1"
								:disabled="pageLoading"
								:loading="pageLoading"
								:id="`person-email`"
								placeholder="Email Id"
								v-model.trim="contactPerson.email"
								:class="{
									required: !contactPerson.email,
								}"
								:rules="[vrules.required(contactPerson.email, 'Email')]"
								:validation-field="{
									url_type: type,
									filter_type: 'contact_person',
									field: 'email',
								}"
								:current-id="contactPerson.id"
							></EmailInput>
						</v-col>
						<v-col md="4" class="mb-3">
							<label class="font-size-14 font-weight-500" for="designation">Designation</label>
							<AutoCompleteInput
								hide-details
								hide-top-margin
								class="mt-1"
								:disabled="pageLoading"
								:loading="pageLoading"
								id="designation"
								placeholder="Designation"
								v-model="contactPerson.designation"
								:items="designationList"
								append-outer-icon="mdi-cog"
								v-on:click:append-outer="manageDesignationDialog = true"
							>
							</AutoCompleteInput>
						</v-col>
					</v-row>
				</v-form>
			</template>
			<template v-slot:action>
				<v-btn v-on:click="$emit('close', true)" depressed tile> Cancel </v-btn>
				<v-btn
					v-on:click="appendContactPerson"
					:disabled="!formValid"
					class="white--text mr-2"
					depressed
					color="blue darken-4"
					tile
				>
					<span v-if="doUpdate">Update</span>
					<span v-else>Add</span>
				</v-btn>
			</template>
		</Dialog>
		<template v-if="manageDesignationDialog">
			<ManageDesignation
				:dialog="manageDesignationDialog"
				:list="designationList"
				v-on:close="manageDesignationDialog = false"
				v-on:success="getOption('designation')"
			></ManageDesignation>
		</template>
	</v-layout>
</template>
<script>
import { mapGetters } from "vuex";
import Dialog from "@/view/components/Dialog";
import TextInput from "@/view/components/TextInput";
import EmailInput from "@/view/components/EmailInput";
import PhoneTemplate from "@/view/components/Phone";
import SelectInput from "@/view/components/SelectInput";
import ManageDesignation from "@/view/components/Manage-Designation.vue";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import { SET_ERROR } from "@/core/services/store/common.module";
import { cloneDeep, find, filter /* lowerCase */ } from "lodash";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";
import PhoneInput from "@/view/components/PhoneInput";
import TextValidateInput from "@/view/components/TextValidateInput";
export default {
	model: {
		prop: "value",
		event: "input",
	},
	data() {
		return {
			formValid: true,
			contactPersons: [],
			designationList: [],
			manageDesignationDialog: false,
			localStorageSaveKey: null,
			pageLoading: false,
			primary: false,
			contactPerson: {
				id: null,
				salutation: "mr",
				first_name: null,
				last_name: null,
				display_name: null,
				email: null,
				mobile: null,
				did: null,
				primary: false,
				designation: null,
			},
		};
	},
	components: {
		Dialog,
		TextInput,
		EmailInput,
		SelectInput,
		ManageDesignation,
		AutoCompleteInput,
		PhoneTemplate,
		PhoneInput,
		TextValidateInput,
	},
	props: {
		value: {
			type: Array,
			default() {
				return [];
			},
		},
		personDialog: {
			type: Boolean,
			default: false,
		},
		parentId: {
			type: [String, Number],
			default: null,
		},
		isTenant: {
			type: Boolean,
			default: false,
		},
		doSave: {
			type: Boolean,
			default: false,
		},
		doUpdate: {
			type: Boolean,
			default: false,
		},
		customerId: {
			type: Number,
			default: 0,
		},
		personId: {
			type: Number,
			default: 0,
		},
		type: {
			type: String,
			default: null,
		},
	},
	watch: {
		personDialog() {
			this.initComponent();
			this.initialize();
		},
		detail: {
			deep: true,
			handler() {
				this.makeDefaultEntry();
			},
		},
	},
	methods: {
		getOption() {
			this.$store.dispatch(SET_LOCAL_DB).then(() => {
				this.initialize();
			});
		},
		initialize() {
			this.designationList = this.localDB("designation", []);
			const isCategoryExist = this.designationList.some(
				(ele) => ele.value.toLowerCase() === this.contactPerson.designation
			);
			if (!isCategoryExist) {
				this.contactPerson.designation = null;
			}
		},
		initComponent() {
			const result = cloneDeep(this.value);
			this.contactPersons = result;
			if (this.doUpdate) {
				this.$nextTick(() => {
					const contact_person = find(this.contactPersons, {
						id: this.personId,
					});
					if (contact_person) {
						this.$nextTick(() => {
							this.contactPerson = {
								id: contact_person.id,
								salutation: contact_person.salutation ?? null,
								first_name: contact_person.first_name ?? null,
								last_name: contact_person.last_name ?? null,
								display_name: contact_person.display_name ?? null,
								email: contact_person.email ?? null,
								mobile: contact_person.mobile ?? null,
								did: contact_person.did ?? null,
								primary: contact_person.primary ?? false,
								designation: contact_person.designation ?? null,
							};
						});
					}
				});
			} else {
				// this.$nextTick(() => {
				// 	this.contactPerson = {
				// 		id: null,
				// 		salutation: "mr",
				// 		first_name: null,
				// 		last_name: null,
				// 		display_name: null,
				// 		email: null,
				// 		mobile: null,
				// 		designation: null,
				// 	};
				// });
			}
		},
		appendDisplayName() {
			if (!this.doUpdate) {
				const result = [];
				const { first_name, last_name } = this.contactPerson;
				if (first_name) {
					result.push(first_name);
				}
				if (last_name) {
					result.push(last_name);
				}
				this.contactPerson.display_name = result.join(" ");
			}
		},
		async appendContactPerson() {
			if (!this.$refs.personForm.validate()) {
				return false;
			}

			// let email = this.contactPerson.email;
			// let phone_number = this.contactPerson.mobile;
			// let did = this.contactPerson.did;
			let display_name = this.contactPerson.display_name;

			// var emailsObj = filter(this.contactPersons, function (contactpersn) {
			// 	if (lowerCase(contactpersn.email) == lowerCase(email)) {
			// 		return contactpersn.email;
			// 	}
			// });
			// if (emailsObj.length > 0) {
			// 	this.$store.commit(SET_ERROR, [{ model: true, message: `Email ${email} already is used` }]);
			// 	return false;
			// }

			// var mobileObj = filter(this.contactPersons, function (contactpersn) {
			// 	if (contactpersn.mobile == phone_number) {
			// 		return contactpersn.mobile;
			// 	}
			// });
			// if (mobileObj.length > 0) {
			// 	this.$store.commit(SET_ERROR, [
			// 		{ model: true, message: `Phone ${phone_number} already is used` },
			// 	]);
			// 	return false;
			// }

			// var didObj = filter(this.contactPersons, function (contactpersn) {
			// 	if (contactpersn.did == did) {
			// 		return contactpersn.did;
			// 	}
			// });
			// if (didObj.length > 0) {
			// 	this.$store.commit(SET_ERROR, [{ model: true, message: `DID ${did} already is used` }]);
			// 	return false;
			// }

			var displayNameObj = filter(this.contactPersons, function (contactpersn) {
				if (contactpersn.display_name == display_name) {
					return contactpersn.display_name;
				}
			});
			if (displayNameObj.length > 0) {
				this.$store.commit(SET_ERROR, [
					{ model: true, message: `Display name ( ${display_name} ) already is used` },
				]);
				return false;
			}

			//console.log({contactPerson:this.contactPerson})

			this.contactPersons.push({
				id: this.contactPerson.id,
				salutation: this.contactPerson.salutation,
				first_name: this.contactPerson.first_name,
				last_name: this.contactPerson.last_name,
				display_name: this.contactPerson.display_name,
				email: this.contactPerson.email,
				mobile: this.contactPerson.mobile,
				did: this.contactPerson.did,
				primary: this.contactPerson.primary,
				designation: this.contactPerson.designation,
			});

			this.$emit("input", this.contactPersons);

			this.$emit("update:person", this.contactPerson);

			this.$nextTick(() => {
				this.contactPerson = {
					id: null,
					salutation: "mr",
					first_name: null,
					last_name: null,
					display_name: null,
					designation: null,
					email: null,
					mobile: null,
					did: null,
					primary: false,
				};
			});
			// clearing local Storage Data
			/* deleteData(this.localStorageSaveKey); */
			this.$emit("close", true);
		},
		/* savetoLocal() {
			const create_contact_person = {
				salutation: this.contactPerson.salutation,
				first_name: this.contactPerson.first_name,
				last_name: this.contactPerson.last_name,
				display_name: this.contactPerson.display_name,
				mobile: this.contactPerson.mobile,
				email: this.contactPerson.email,
				designation: this.contactPerson.designation,
			};
			saveData(this.localStorageSaveKey, create_contact_person);
		}, */
		/* loadLocalData() {
			let localSaveContactPerson = getData(this.localStorageSaveKey);
			if (localSaveContactPerson) {
				this.contactPerson = {
					salutation: localSaveContactPerson.salutation,
					first_name: localSaveContactPerson.first_name,
					last_name: localSaveContactPerson.last_name,
					display_name: localSaveContactPerson.display_name,
					mobile: localSaveContactPerson.mobile,
					email: localSaveContactPerson.email,
					designation: localSaveContactPerson.designation,
				};
			}
		}, */
	},
	mounted() {
		this.initComponent();
		this.initialize();
	},
	computed: {
		...mapGetters(["localDB"]),
	},
};
</script>
