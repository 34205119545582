<template>
	<v-row class="mt-3">
		<v-col md="12" class="pt-0 d-flex align-items-center">
			<div class="heading-title h4 mb-0 blue--text text--darken-4">
				Contact Persons ({{ contactPersons.length }})
			</div>
			<v-spacer></v-spacer>
			<v-btn v-on:click="dialog = true" class="white--text mr-2" depressed color="blue darken-4" tile>
				Add Contact Person
			</v-btn>
		</v-col>
		<v-col md="12">
			<v-simple-table class="bt-table">
				<thead>
					<tr>
						<th class="simple-table-th" width="60">Primary</th>
						<th class="simple-table-th">Salutation</th>
						<th class="simple-table-th">First Name</th>
						<th class="simple-table-th">Last Name</th>
						<th class="simple-table-th">Display Name</th>
						<th class="simple-table-th">Phone Number</th>
						<th class="simple-table-th" width="150">DID</th>
						<th class="simple-table-th">Email</th>
						<th class="simple-table-th">Designation</th>
						<th class="simple-table-th"></th>
					</tr>
				</thead>
				<tbody v-if="contactPersons.length">
					<tr v-for="(row, index) in contactPersons" :key="index">
						<td class="cursor-default simple-table-td">
							<div class="d-flex align-items-center">
								<v-checkbox
									hide-details
									color="blue darken-4"
									class="ma-0 pa-0 disable-color-primary"
									v-model="row.primary"
									:disabled="isDisable(row.primary)"
									v-on:change="makePrimary(index)"
								></v-checkbox>
								<v-chip class="mx-2 px-2" label x-small color="green" v-if="row.primary">
									<span class="white--text fw-500">Primary</span>
								</v-chip>
							</div>
						</td>
						<td class="cursor-default simple-table-td text-capitalize">{{ row.salutation }}.</td>
						<td class="cursor-default simple-table-td">
							<ShowValue :object="row" object-key="first_name" label="first name"></ShowValue>
							<!-- {{ row.first_name }} -->
						</td>
						<td class="cursor-default simple-table-td">
							<!-- {{ row.last_name }} -->
							<ShowValue :object="row" object-key="last_name" label="last name"></ShowValue>
						</td>
						<td class="cursor-default simple-table-td">
							<!-- {{ row.display_name }} -->
							<ShowValue :object="row" object-key="display_name" label="display name"></ShowValue>
						</td>
						<td class="cursor-default simple-table-td">
							<!-- {{ row.mobile }} -->
							<ShowValue :object="row" object-key="mobile" label="phone number"></ShowValue>
						</td>
						<td class="cursor-default simple-table-td">
							<!-- {{ row.did }} -->
							<ShowValue :object="row" object-key="did" label="did"></ShowValue>
						</td>
						<td class="cursor-default simple-table-td">
							<!-- {{ row.email }} -->
							<ShowValue :object="row" object-key="email" label="email"></ShowValue>
						</td>
						<td class="cursor-default simple-table-td">
							<!-- {{ row.designation }} -->
							<ShowValue :object="row" object-key="designation" label="designation"></ShowValue>
						</td>
						<td class="simple-table-td">
							<v-btn
								class="mx-2"
								color="blue darken-4"
								dark
								fab
								x-small
								v-on:click="editContactPerson(index, row)"
							>
								<v-icon small dark> mdi-pencil</v-icon>
							</v-btn>
							<template v-if="!row.primary">
								<v-btn
									class="mx-2"
									color="red lighten-1"
									dark
									fab
									x-small
									v-on:click="removeContactPerson(index, row)"
								>
									<v-icon small dark> mdi-trash-can-outline</v-icon>
								</v-btn>
							</template>
						</td>
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td colspan="8">
							<p class="my-4 row-not-found text-center font-weight-500 font-size-16">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There are no contact person at the moment.
							</p>
						</td>
					</tr>
				</tfoot>
			</v-simple-table>
		</v-col>
		<CreateContactPerson
			v-if="dialog"
			v-model="contactPersons"
			:parent-id="parentId"
			:person-dialog="dialog"
			:type="type"
			v-on:close="dialog = false"
			v-on:update:person="$emit('saveToLocal', contactPersons)"
		></CreateContactPerson>
		<UpdateContactPerson
			v-if="updateDialog"
			v-model="updateContactPerson"
			:person-dialog="updateDialog"
			:parent-id="parentId"
			:type="type"
			:persons="contactPersons"
			v-on:change="updatePerson($event)"
			v-on:close="updateDialog = false"
			v-on:update:person="$emit('saveToLocal', contactPersons)"
		></UpdateContactPerson>
	</v-row>
</template>

<script>
import CreateContactPerson from "@/view/components/Create-Contact-Person";
import UpdateContactPerson from "@/view/components/Update-Contact-Person";
import ShowValue from "@/view/components/ShowValue";
import { DeleteContactPerson } from "@/core/lib/contact.lib";
import { cloneDeep, map } from "lodash";

export default {
	name: "contact-person",
	title: "Customer",
	props: {
		detail: {
			type: Object,
			default: function () {
				return {};
			},
		},
		type: {
			type: String,
			default: "",
		},
		url: {
			type: String,
			default: "",
		},
		customer: {
			type: Object,
			default: function () {
				return {};
			},
		},
		defaultContactPersons: {
			type: Array,
			default: function () {
				return [];
			},
		},
		isDialog: {
			type: Boolean,
			default: false,
		},
		person: {
			type: Number,
			default: 0,
		},
		parentId: {
			type: [String, Number],
			default: null,
		},
		updateContact: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			dialog: false,
			updateDialog: false,
			primary: 0,
			contactPerson: {
				id: null,
				title: "mr",
				first_name: null,
				last_name: null,
				display_name: null,
				email: null,
				phone: null,
				did: null,
				designation: null,
				primary: false,
				default: 0,
			},
			editIndex: -1,
			contactPersons: [],
			updateContactPerson: {},
		};
	},
	watch: {
		contactPersons: {
			deep: true,
			immediate: true,
			handler(param) {
				this.$emit("saveContactPerson", param);
			},
		},
		detail: {
			deep: true,
			immediate: true,
			handler() {
				this.makeDefaultEntry();
			},
		},
	},
	methods: {
		makePrimary(index) {
			const result = cloneDeep(this.contactPersons);
			const _elem = map(result, (element) => {
				return { ...element, primary: false };
			});
			_elem[index].primary = true;
			this.contactPersons = _elem;
		},
		updatePerson(row) {
			this.contactPersons.splice(this.editIndex, 1, row);
		},
		appendDisplayName() {
			const result = [];
			const { first_name, last_name } = this.contactPerson;
			if (first_name) {
				result.push(first_name);
			}
			if (last_name) {
				result.push(last_name);
			}
			this.contactPerson.display_name = result.join(" ");
		},
		appendDialogDisplayName(index) {
			const result = [];
			const { first_name, last_name } = this.contactPersons[index];
			if (first_name) {
				result.push(first_name);
			}
			if (last_name) {
				result.push(last_name);
			}
			this.contactPersons[index].display_name = result.join(" ");
		},
		makeDefaultEntry() {
			const _this = this;
			try {
				if (_this.updateContact) {
					_this.contactPersons = _this.defaultContactPersons;
				} else {
					if (_this.detail.display_name) {
						let firstEntry = {
							id: null,
							uuid: null,
							salutation: "mr",
							first_name: _this.detail.first_name ? _this.detail.first_name : null,
							last_name: _this.detail.last_name ? _this.detail.last_name : null,
							display_name: _this.detail.display_name ? _this.detail.display_name : null,
							designation: _this.detail.designation ? _this.detail.designation : null,
							email: _this.detail.email ? _this.detail.email : null,
							mobile: _this.detail.phone_no ? _this.detail.phone_no : null,
							did: _this.detail.did ? _this.detail.phone_no : null,
							primary: _this.detail.primary ? _this.detail.primary : false,
						};
						_this.contactPersons.splice(0, 1, firstEntry);
					}
					if (_this.defaultContactPersons.length) {
						_this.contactPersons = _this.defaultContactPersons;
					}
				}
			} catch (error) {
				_this.contactPersons.splice(0, 1);
				_this.logError(error);
			}
		},
		createDisplayName() {
			let firstname = "";
			if (this.detail.first_name) {
				firstname = this.detail.first_name.toLowerCase();
			}
			let lastname = "";
			if (this.detail.last_name) {
				lastname = this.detail.last_name.toLowerCase();
			}
			return firstname + lastname;
		},
		/* pushContactPerson() {
			this.contactPersons.push({
				id: null,
				title: "mr",
				menu: false,
				first_name: null,
				last_name: null,
				display_name: null,
				primary_email: null,
				emails: [],
				primary_phone: null,
				will_notified: 1,
				designation: null,
				position: null,
				default: 0,
			});
		}, */
		async removeContactPerson(index, data) {
			if (data.id && data.uuid) {
				await DeleteContactPerson({ url: this.url, uuid: data.uuid });
				this.contactPersons.splice(index, 1);
			} else {
				this.contactPersons.splice(index, 1);
				this.$emit("saveToLocal", this.contactPersons);
			}
			if (data.primary == 1) {
				this.contactPersons[0].primary = true;
			}
		},
		editContactPerson(index, data) {
			this.editIndex = index;
			this.updateDialog = true;
			this.updateContactPerson = data;
		},
	},
	computed: {
		isDisable: () => {
			return function (x) {
				if (x) {
					return true;
				}
				if (this.contactPersons.length === 1) {
					this.contactPersons[0].primary = true;
					return true;
				}
				return false;
			};
		},
	},
	components: {
		CreateContactPerson,
		UpdateContactPerson,
		ShowValue,
	},
	mounted() {
		/* if (this.isDialog) {
			if (this.contactPersons.length <= 0) {
				this.contactPersons.push({
					id: null,
					title: "mr",
					menu: false,
					first_name: null,
					last_name: null,
					display_name: null,
					primary_email: null,
					primary_phone: null,
					emails: [],
					will_notified: 1,
					position: null,
					designation: null,
					default: 0,
				});
			}
		} */
		this.makeDefaultEntry();
		if (this.person > 0) {
			this.contactPersons = [this.getPersonById(this.customer, this.person)];
		}
	},
};
</script>
