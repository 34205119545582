<template>
	<v-sheet class="vendor" id="vendor" style="height: calc(100vh - 88px)">
		<CreateDialog
			:dialog="createDialog"
			v-on:close="
				createDialog = false;
				clearLocalData();
			"
		>
			<template v-slot:title>
				<span>{{ pageTitle() }}</span
				>&nbsp;
				<span class="orange--text text--darken-4 barcode-font-size">#{{ vendor.barcode }}</span>
			</template>
			<template v-slot:body>
				<template v-if="!barcodeLoading">
					<v-stepper flat tile class="item-group-stepper" v-model="stepper">
						<v-stepper-header>
							<v-stepper-step
								color="blue darken-4"
								class="py-2 btx-label"
								:complete="stepper > 1"
								step="1"
							>
								Overview
							</v-stepper-step>

							<v-divider></v-divider>

							<v-stepper-step
								color="blue darken-4"
								class="py-2 btx-label"
								:complete="stepper > 2"
								step="2"
							>
								Contact Persons
							</v-stepper-step>

							<v-divider></v-divider>

							<v-stepper-step
								color="blue darken-4"
								class="py-2 btx-label"
								:complete="stepper > 3"
								step="3"
							>
								Address
							</v-stepper-step>
						</v-stepper-header>

						<v-stepper-items>
							<v-stepper-content class="pt-0" step="1">
								<perfect-scrollbar
									:options="{ suppressScrollX: true }"
									class="scroll custom-box-top-inner-shadow"
									style="max-height: calc(100vh - 185px); position: relative"
								>
									<v-form
										ref="overviewForm"
										v-model.trim="formValid"
										lazy-validation
										v-on:submit.stop.prevent="updateOrCreate()"
									>
										<v-row>
											<v-col md="8">
												<v-row>
													<template>
														<v-col md="3" class="my-auto py-0">
															<label for="first-name" class="btx-label mt-2 required">Primary Contact</label>
														</v-col>
														<v-col md="9" class="py-0">
															<v-layout>
																<v-flex class="max-width-100px">
																	<SelectInput
																		hide-details
																		:disabled="pageLoading"
																		:loading="pageLoading"
																		:items.sync="listTitle"
																		id="salutation"
																		placeholder="Title"
																		v-model="vendor.salutation"
																		v-on:change="updateDefaultPerson(), savetoLocal()"
																	>
																	</SelectInput>
																</v-flex>
																<v-flex class="mx-2">
																	<TextInput
																		hide-details
																		:disabled="pageLoading"
																		:loading="pageLoading"
																		id="first-name"
																		placeholder="First Name"
																		:rules="[vrules.required(vendor.first_name, 'First Name')]"
																		:class="{
																			required: !vendor.first_name,
																		}"
																		v-model="vendor.first_name"
																		v-on:change="savetoLocal()"
																		v-on:keyup="updateDefaultPerson()"
																	>
																	</TextInput>
																</v-flex>
																<v-flex>
																	<TextInput
																		hide-details
																		:disabled="pageLoading"
																		:loading="pageLoading"
																		id="last-name"
																		placeholder="Last Name"
																		v-model="vendor.last_name"
																		v-on:change="savetoLocal()"
																		v-on:keyup="updateDefaultPerson()"
																	></TextInput>
																</v-flex>
															</v-layout>
														</v-col>
													</template>
													<!-- <v-col md="3" class="my-auto py-0">
														<label for="supplier-id" class="btx-label mt-2 required">Supplier #</label>
													</v-col>
													<v-col md="9" class="py-0">
														<TextInput
															hide-details
															:disabled="pageLoading || uuid ? true : false"
															:loading="pageLoading"
															id="supplier-id"
															placeholder="Supplier #"
															:rules="[vrules.required(vendor.barcode, 'Supplier #'), barcodeError ? false : true]"
															:class="{
																required: !vendor.barcode,
															}"
															v-model="vendor.barcode"
															v-on:change="validateBarcode(vendor.barcode, 'supplier'), savetoLocal()"
														>
														</TextInput>
														<span v-if="barcodeError" class="red--text text--darken-1 font-small">{{
															barcodeError
														}}</span>
													</v-col> -->
													<v-col md="3" class="my-auto py-0">
														<label for="company-name" class="btx-label mt-2 required">Company Name</label>
													</v-col>
													<v-col md="9" class="py-0">
														<TextValidateInput
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															id="company-name"
															placeholder="Company Name"
															:rules="[vrules.required(vendor.company_name, 'Company Name')]"
															:class="{
																required: !vendor.company_name,
															}"
															:validationField="{
																url_type: 'supplier',
																filter_type: 'contact',
																field: 'company_name',
															}"
															:parent-id="vendor.id"
															show-dropdown
															v-model="vendor.company_name"
															v-on:change="savetoLocal()"
															v-on:keyup="updateVendorDisplayName()"
														></TextValidateInput>
													</v-col>
													<v-col md="3" class="my-auto py-0">
														<label for="display-name" class="btx-label mt-2 required"
															>Display Name
															<TooltipQuestion>
																<template v-slot:text
																	>This name will be displayed on<br />the transactions you create for<br />this
																	supplier</template
																>
															</TooltipQuestion>
														</label>
													</v-col>
													<v-col md="9" class="py-0">
														<!-- <TextInput
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															id="display-name"
															:rules="[vrules.required(vendor.display_name, 'Display Name')]"
															:class="{
																required: !vendor.display_name,
															}"
															placeholder="Display Name"
															v-model="vendor.display_name"
															v-on:change="savetoLocal()"
															v-on:keyup="updateDefaultPerson()"
														>
														</TextInput> -->
														<TextValidateInput
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															placeholder="Display Name"
															id="display-name"
															:rules="[vrules.required(vendor.display_name, 'Display Name')]"
															:class="{
																required: !vendor.display_name,
															}"
															:validationField="{
																url_type: 'supplier',
																filter_type: 'contact',
																field: 'display_name',
															}"
															:parent-id="vendor.id"
															show-dropdown
															v-model="vendor.display_name"
															v-on:change="savetoLocal()"
														>
														</TextValidateInput>
													</v-col>
													<v-col md="3" class="py-2">
														<label for="display-name" class="btx-label mt-2">Business decription</label>
													</v-col>
													<v-col md="9" class="py-0">
														<TextAreaInput
															:id="`business-description`"
															placeholder="Description"
															v-model="vendor.business_description"
															v-on:change="savetoLocal()"
															:disabled="pageLoading"
															:loading="pageLoading"
														></TextAreaInput>
													</v-col>
													<v-col md="3" class="my-auto py-0">
														<label for="category-name" class="btx-label mt-2 required">Category </label>
													</v-col>
													<v-col md="9" class="py-0">
														<AutoCompleteInput
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															id="category-name"
															placeholder="Category"
															v-model="vendor.category"
															:items="categoryList"
															item-text="category_name"
															item-value="id"
															append-outer-icon="mdi-cog"
															:rules="[vrules.required(vendor.category, 'Category')]"
															:class="{
																required: !vendor.category,
															}"
															v-on:change="savetoLocal()"
															v-on:click:append-outer="manageCategoryDialog = true"
														>
														</AutoCompleteInput>
													</v-col>
													<v-col md="3" class="my-auto py-0">
														<label for="une" class="btx-label mt-2">UEN </label>
													</v-col>
													<v-col md="9" class="py-0">
														<TextValidateInput
															hide-details
															:disabled="pageLoading"
															url-type="supplier"
															field-name="uen"
															:loading="pageLoading"
															id="uen-number"
															:validation-field="{
																url_type: 'supplier',
																filter_type: 'contact',
																field: 'uen',
															}"
															:parent-id="vendor.id"
															show-dropdown
															placeholder="UEN Number"
															@change="savetoLocal()"
															v-model="vendor.uen"
															v-on:keyup="updateDefaultPerson()"
														>
														</TextValidateInput>
														<!-- <TextValidateInput
															hide-details
															:disabled="pageLoading"
															url-type="customer"
															field-name="uen"
															:validation-field="{
																url_type: 'customer',
																filter_type: 'contact',
																field: 'uen',
															}"
															:parent-id="customer.id"
															show-dropdown
															:loading="pageLoading"
															id="uen-number"
															placeholder="UEN Number"
															@change="savetoLocal()"
															v-model="customer.uen"
															v-on:keyup="updateDefaultPerson()"
														>
														</TextValidateInput> -->
													</v-col>
													<template>
														<v-col md="3" class="my-auto py-0">
															<label for="vendor-email" class="btx-label mt-2 required"
																>Company Email Address</label
															>
														</v-col>
														<v-col md="9" class="py-0">
															<EmailInput
																hide-details
																:disabled="pageLoading"
																:loading="pageLoading"
																id="vendor-email"
																placeholder="Company Email Address"
																v-model="vendor.email"
																:rules="[vrules.required(vendor.email, 'Company Email Address')]"
																:class="{
																	required: !vendor.email,
																}"
																:validation-field="{
																	url_type: 'supplier',
																	filter_type: 'contact',
																	field: 'email',
																}"
																:parent-id="vendor.id"
																v-on:keyup="updateDefaultPerson()"
																v-on:onChange="savetoLocal()"
															>
															</EmailInput>
														</v-col>
													</template>
													<v-col md="3" class="my-auto py-0">
														<label for="vendor-phone-no" class="btx-label mt-2 required">Company Phone no</label>
													</v-col>
													<v-col md="9" class="py-0">
														<PhoneInput
															required
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															id="vendor-phone-no"
															v-on:onChange="savetoLocal()"
															placeholder="Company Phone No."
															v-model="vendor.phone_no"
															:validation-field="{
																url_type: 'supplier',
																filter_type: 'contact',
																field: 'phone_number',
															}"
															:parent-id="vendor.id"
															v-on:keyup="updateDefaultPerson()"
														></PhoneInput>
													</v-col>
													<v-col md="3" class="py-0">
														<label for="supplier-attachment" class="btx-label mt-2">Attachments</label>
													</v-col>
													<v-col md="9" class="py-0">
														<FileUpload v-model="vendor.attachments"> </FileUpload>
													</v-col>
													<template v-if="false">
														<v-col md="3" class="my-auto py-0">
															<label for="vendor-fax" class="btx-label mt-2">Fax</label>
														</v-col>
														<v-col md="9" class="py-0">
															<PhoneTemplate
																hide-details
																:disabled="pageLoading"
																:loading="pageLoading"
																id="vendor-fax"
																placeholder="Fax"
																v-model="vendor.fax"
															></PhoneTemplate>
														</v-col>
														<v-col md="3" class="my-auto py-0">
															<label for="vendor-website" class="btx-label mt-2">Website</label>
														</v-col>
														<v-col md="9" class="py-0">
															<TextInput
																hide-details
																:disabled="pageLoading"
																:loading="pageLoading"
																id="vendor-website"
																placeholder="Website"
																v-model="vendor.website"
															>
															</TextInput>
														</v-col>
														<v-col md="3" class="py-0">
															<label for="vendor-remark" class="btx-label mt-2"
																>Remarks<br /><span class="text-muted">(For Internal Use)</span></label
															>
														</v-col>
														<v-col md="9" class="py-0">
															<TextAreaInput
																hide-details
																:disabled="pageLoading"
																:loading="pageLoading"
																:rows="5"
																id="vendor-remark"
																placeholder="Remarks (For Internal Use)"
																v-model="vendor.remark"
															></TextAreaInput>
														</v-col>
													</template>
												</v-row>
											</v-col>
											<v-col md="4">
												<v-row>
													<v-col md="12" class="my-6 py-0 text-center">
														<Avatar can-change v-model="vendor.image"></Avatar>
													</v-col>
												</v-row>
											</v-col>
										</v-row>
									</v-form>
								</perfect-scrollbar>
							</v-stepper-content>
							<v-stepper-content class="pt-0" step="2">
								<perfect-scrollbar
									:options="{ suppressScrollX: true }"
									class="scroll custom-box-top-inner-shadow"
									style="max-height: calc(100vh - 185px); position: relative"
								>
									<v-form
										ref="personForm"
										v-model.trim="formValid"
										lazy-validation
										v-on:submit.stop.prevent="updateOrCreate()"
									>
										<ContactPerson
											:detail="vendor"
											v-on:saveContactPerson="updateContactPerson"
											:update-contact="updateSupplier"
											:default-contact-persons="contact_person"
											url="supplier/contact"
											type="supplier"
											:parent-id="vendor.id"
										>
										</ContactPerson>
									</v-form>
								</perfect-scrollbar>
							</v-stepper-content>
							<v-stepper-content class="pt-0" step="3">
								<perfect-scrollbar
									:options="{ suppressScrollX: true }"
									class="scroll custom-box-top-inner-shadow"
									style="max-height: calc(100vh - 185px); position: relative"
								>
									<v-form
										ref="addressForm"
										v-model.trim="formValid"
										lazy-validation
										v-on:submit.stop.prevent="updateOrCreate()"
									>
										<v-row>
											<v-col md="6">
												<label class="btx-label mt-2">ADDRESS</label>
												<hr />
											</v-col>
											<v-col md="6" v-if="false">
												<label class="btx-label mt-2">SHIPPING ADDRESS</label>
												<hr />
											</v-col>
										</v-row>
										<v-row v-if="false">
											<v-col md="6"></v-col>
											<v-col md="6">
												<v-btn
													v-on:click="copyBilling"
													text
													depressed
													tile
													color="blue darken-4"
													:disabled="pageLoading"
												>
													<v-icon left>mdi-arrow-down-bold</v-icon>
													Copy Billing Address
												</v-btn>
											</v-col>
										</v-row>
										<v-row>
											<v-col md="6">
												<perfect-scrollbar
													:options="{ suppressScrollX: true }"
													class="scroll custom-box-top-inner-shadow"
													style="max-height: 60vh; position: relative"
												>
													<v-row>
														<v-col md="3" class="my-auto py-0">
															<label for="loacation" class="btx-label mt-2 required">Location</label>
														</v-col>
														<v-col md="9" class="py-0">
															<TextInput
																hide-details
																:disabled="pageLoading"
																:loading="pageLoading"
																id="location"
																placeholder="Location"
																v-model="billingAddress.location"
																:rules="[vrules.required(billingAddress.location, 'Location')]"
																:class="{ required: !billingAddress.location }"
																@change="saveBillingAddressLocal()"
															></TextInput>
														</v-col>
														<v-col md="3" class="my-auto py-0">
															<label for="postal-code" class="btx-label mt-2 text-right required"
																>Postal Code
															</label>
														</v-col>
														<v-col md="9" class="py-0">
															<v-text-field
																outlined
																class="mt-3"
																hide-details
																:disabled="pageLoading"
																:loading="pageLoading"
																id="postal-code"
																@change="saveBillingAddressLocal()"
																placeholder="Postal Code"
																type="number"
																v-mask="'######'"
																v-model="billingAddress.address_postal_code"
																:rules="[
																	vrules.required(billingAddress.address_postal_code, 'address postal code'),
																	vrules.phoneNumber(billingAddress.address_postal_code, 'address postal code', 6),
																]"
																:class="{
																	required: !billingAddress.address_postal_code,
																}"
															></v-text-field>
														</v-col>
														<v-col md="3" class="my-auto py-0">
															<label for="address-line-1" class="btx-label mt-2 required">Address Line 1 </label>
														</v-col>
														<v-col md="9" class="py-0">
															<TextInput
																hide-details
																:disabled="pageLoading"
																:loading="pageLoading"
																id="address-line-1"
																placeholder="Address Line 1"
																:rules="[vrules.required(billingAddress.address_line_1, 'Address line 1')]"
																:class="{ required: !billingAddress.address_line_1 }"
																v-model="billingAddress.address_line_1"
																@change="saveBillingAddressLocal()"
															></TextInput>
														</v-col>
														<v-col md="3" class="my-auto py-0">
															<label for="address-line-2" class="btx-label mt-2">Address Line 2 </label>
														</v-col>
														<v-col md="9" class="py-0">
															<TextInput
																hide-details
																:disabled="pageLoading"
																:loading="pageLoading"
																id="address-line-2"
																placeholder="Address Line 2"
																v-model="billingAddress.address_line_2"
																@change="saveBillingAddressLocal()"
															></TextInput>
														</v-col>
														<v-col md="3" class="my-auto py-0">
															<label for="unit-number" class="btx-label mt-2">Unit No. </label>
														</v-col>
														<v-col md="9" class="py-0">
															<TextInput
																hide-details
																:disabled="pageLoading"
																:loading="pageLoading"
																id="unit-number"
																placeholder="Unit No."
																v-model="billingAddress.unit_number"
																@change="saveBillingAddressLocal()"
															>
															</TextInput>
														</v-col>
														<v-col md="3" class="my-auto py-0">
															<label for="address-country" class="btx-label mt-2">Country </label>
														</v-col>
														<v-col md="9" class="py-0">
															<TextInput
																hide-details
																:disabled="pageLoading"
																:loading="pageLoading"
																id="address-country"
																placeholder="Country"
																v-model="billingAddress.address_country"
																@change="saveBillingAddressLocal()"
															></TextInput>
														</v-col>
														<AddressTemplate v-if="false" v-model="address"> </AddressTemplate>
													</v-row>
												</perfect-scrollbar>
											</v-col>
										</v-row>
									</v-form>
								</perfect-scrollbar>
							</v-stepper-content>
						</v-stepper-items>
					</v-stepper>
				</template>
				<template v-else>
					<div class="mx-20 my-40 text-center">
						<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
					</div>
				</template>
			</template>
			<template v-slot:action>
				<v-btn
					v-if="stepper > 1"
					class="white--text mr-2"
					depressed
					color="blue darken-4"
					tile
					:disabled="pageLoading"
					v-on:click="goBackStepper()"
				>
					Back
				</v-btn>
				<v-btn
					:disabled="pageLoading"
					:loading="pageLoading"
					class="white--text mr-2"
					depressed
					color="blue darken-4"
					tile
					v-on:click="updateOrCreate()"
				>
					Save <template v-if="stepper < 3">&amp; Next</template>
				</v-btn>
				<v-btn
					v-if="uuid && stepper < 3"
					:loading="pageLoading"
					class="white--text mr-2"
					depressed
					color="blue darken-4"
					tile
					:disabled="pageLoading"
					v-on:click="updateCustomerDirect()"
				>
					Save
				</v-btn>
			</template>
		</CreateDialog>
		<ManageSupplierCategory
			v-if="manageCategoryDialog"
			:dialog="manageCategoryDialog"
			:category="categoryList"
			v-on:close-dialog="manageCategoryDialog = false"
			v-on:success="getCategories"
		></ManageSupplierCategory>
	</v-sheet>
</template>
<script>
import { findIndex, toSafeInteger, map } from "lodash";
import { mapGetters } from "vuex";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import {
	CreateOrUpdateVendor,
	GetVendor,
	ValidateCompanyName,
	ValidateContact,
} from "@/core/lib/contact.lib";
import TextInput from "@/view/components/TextInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import EmailInput from "@/view/components/EmailInput";
import SelectInput from "@/view/components/SelectInput";
import AddressTemplate from "@/view/components/AddressTemplate";
import FileUpload from "@/view/components/FileUpload";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import PhoneTemplate from "@/view/components/Phone";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TooltipQuestion from "@/view/components/TooltipQuestion";
import Avatar from "@/view/components/Avatar";
import ApiService from "@/core/services/api.service";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";
import ContactPerson from "@/view/components/ContactPerson";
import CreateDialog from "@/view/components/CreateDialog";
import TextValidateInput from "@/view/components/TextValidateInput";
import ManageSupplierCategory from "@/view/components/Manage-Supplier-Category";
import ObjectPath from "object-path";
import { saveData, getData, deleteData } from "@/core/services/local.service";
import PhoneInput from "@/view/components/PhoneInput";
export default {
	name: "vendor-create",
	title: "Create Vendor",
	data() {
		return {
			uuid: null,
			cloneUUID: null,
			barcode: null,
			companyNameError: null,
			formValid: true,
			createDialog: true,
			designationLists: [],
			updateSupplier: false,
			pageLoading: false,
			manageCategoryDialog: false,
			billingAddress: {
				id: null,
				uuid: null,
				name: null,
				location: null,
				address_line_1: null,
				address_line_2: null,
				unit_number: null,
				address_country: "Singapore",
				address_postal_code: null,
				description: null,
			},
			manageDesignationDialog: false,
			stepper: 1,
			vendor: {
				id: null,
				uuid: null,
				salutation: "mr",
				first_name: null,
				last_name: null,
				uen: null,
				company_name: null,
				image: null,
				designation: null,
				display_name: null,
				email: null,
				phone_no: null,
				fax: null,
				website: null,
				remark: null,
				business_description: null,
				category: null,
				attachments: [],
			},
			categoryList: [],
			shippingAddress: {
				id: null,
				uuid: null,
				name: null,
				id_number: null,
				location: null,
				latitude: null,
				longitude: null,
				address_line_1: null,
				address_line_2: null,
				address_city: null,
				address_state: null,
				address_country: "Singapore",
				address_postal_code: null,
				description: null,
				has_lat_long: 0,
			},
			address: {
				id: null,
				uuid: null,
				name: null,
				id_number: null,
				location: null,
				latitude: null,
				longitude: null,
				address_line_1: null,
				address_line_2: null,
				address_city: null,
				address_state: null,
				address_country: "Singapore",
				address_postal_code: null,
				description: null,
				has_lat_long: 0,
			},
			contact_person: [
				{
					id: null,
					uuid: null,
					salutation: "mr",
					first_name: null,
					last_name: null,
					display_name: null,
					email: null,
					did: null,
					mobile: null,
					primary: false,
					designation: null,
				},
			],
		};
	},
	components: {
		CreateDialog,
		Avatar,
		TextInput,
		TextAreaInput,
		TooltipQuestion,
		FileUpload,
		AddressTemplate,
		AutoCompleteInput,
		ContactPerson,
		PhoneTemplate,
		EmailInput,
		SelectInput,
		ManageSupplierCategory,
		TextValidateInput,
		PhoneInput,
	},

	methods: {
		getDesignations() {
			this.pageLoading = true;
			ApiService.setHeader();
			ApiService.get(`contact-options`)
				.then(({ data }) => {
					if (data.designation) {
						this.designationLists = data.designation;
					}
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getCategories(param) {
			const isCategoryExist = param.some((ele) => ele.id === this.vendor.category);
			if (!isCategoryExist) {
				this.vendor.category = null;
			}
			this.categoryList = param;
		},
		copyBilling() {
			this.shippingAddress = {
				id: null,
				uuid: null,
				name: this.billingAddress.name ? this.billingAddress.name : null,
				id_number: null,
				location: null,
				latitude: null,
				longitude: null,
				address_line_1: this.billingAddress.address_line_1 ? this.billingAddress.address_line_1 : null,
				address_line_2: this.billingAddress.address_line_2 ? this.billingAddress.address_line_2 : null,
				address_city: null,
				address_state: null,
				address_country: this.billingAddress.address_country
					? this.billingAddress.address_country
					: null,
				address_postal_code: this.billingAddress.address_postal_code
					? this.billingAddress.address_postal_code
					: null,
				description: null,
				has_lat_long: 0,
			};
		},
		validateRadio(index) {
			let contactPersons = this.contact_person;
			this.contact_person = contactPersons.map((row) => {
				row.primary = false;
				return row;
			});
			this.contact_person[index].primary = true;
		},
		updateVendorDisplayName() {
			const route_name = ObjectPath.get(this.$route, "name");
			if (route_name == "supplier-update") {
				return false;
			}
			this.vendor.display_name = this.vendor.company_name;
			this.updateDefaultPerson();
		},
		updateVendorDisplayName_old() {
			const vendor = this.vendor;
			if (vendor) {
				const display_name = [];
				if (vendor.company_name) {
					display_name.push(vendor.company_name);
				} else {
					if (vendor.first_name) {
						display_name.push(vendor.first_name);
					}
					if (vendor.last_name) {
						display_name.push(vendor.last_name);
					}
				}
				this.vendor.display_name = display_name.join(" ");
				this.updateDefaultPerson();
			}
		},
		updatePersonDisplayName(index) {
			const contact_person = this.contact_person[index];
			if (contact_person) {
				const display_name = [];
				if (contact_person.first_name) {
					display_name.push(contact_person.first_name);
				}
				if (contact_person.last_name) {
					display_name.push(contact_person.last_name);
				}
				this.contact_person[index].display_name = display_name.join(" ");
			}
		},
		updateDefaultPerson() {
			const { salutation, first_name, display_name, last_name, email, phone_no, designation } =
				this.vendor;
			//if (email) {
			let defaultIndex = toSafeInteger(findIndex(this.contact_person, { email }));

			if (defaultIndex < 0) {
				defaultIndex = 0;
			}

			const person = this.contact_person[defaultIndex];

			if (!person) {
				return false;
			}

			this.contact_person.splice(defaultIndex, 1, {
				id: person.id,
				uuid: person.uuid,
				salutation,
				first_name,
				last_name,
				email,
				display_name,
				mobile: phone_no,
				did: phone_no,
				primary: true,
				designation: designation,
			});
			//}
		},
		removePerson(index) {
			this.contact_person.splice(index, 1);
			if (this.contact_person.length <= 0) {
				this.addPerson();
			}
		},
		addPerson() {
			this.contact_person.push({
				id: null,
				uuid: null,
				salutation: "mr",
				first_name: null,
				last_name: null,
				email: null,
				primary: false,
				full_name: null,
				default: false,
				work_phone: null,
				mobile: null,
			});
		},
		async updateOrCreate() {
			const _this = this;

			if (_this.stepper == 1) {
				console.log(this.vendor.category);
				const formErrors = _this.validateForm(_this.$refs.overviewForm);
				if (formErrors.length) {
					_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
					return false;
				}

				if (!_this.$refs.overviewForm.validate()) {
					return false;
				}
				const _vendor = _this.vendor;
				const formField = [
					{
						field: "company_name",
						value: _vendor.company_name,
					},
					{
						field: "display_name",
						value: _vendor.display_name,
					},
					{
						field: "uen",
						value: _vendor.uen,
					},
					{
						field: "phone_number",
						value: _vendor.phone_no,
					},
					{
						field: "email",
						value: _vendor.email,
					},
				];
				const validatePayload = {
					id: _vendor.id,
					filter_type: "contact",
					fields: formField,
					data: null,
				};
				_this.pageLoading = true;
				const { exists, error_message } = await ValidateContact("supplier", validatePayload);
				_this.pageLoading = false;
				if (exists) {
					if (error_message) {
						_this.$store.commit(SET_ERROR, [{ model: true, message: error_message }]);
					}
					return false;
				}
			}

			if (_this.stepper == 2) {
				if (_this.contact_person.length == 0) {
					_this.$store.commit(SET_ERROR, [
						{ model: true, message: "Please Add Atleast One Contact Person." },
					]);
					return false;
				}

				const formErrors = _this.validateForm(_this.$refs.personForm);
				if (formErrors.length) {
					_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
					return false;
				}

				if (!_this.$refs.personForm.validate()) {
					return false;
				}
				const _vendor = _this.vendor;
				const _contact_person = map(this.contact_person, (row) => {
					return {
						id: row.id ? row.id : null,
						fields: [
							{
								field: "did",
								value: row.did,
							},
							{
								field: "display_name",
								value: row.display_name,
							},
							{
								field: "phone_number",
								value: row.mobile,
							},
							{
								field: "email",
								value: row.email,
							},
						],
					};
				});
				const validatePayload = {
					id: _vendor.id,
					filter_type: "contact_person",
					fields: null,
					data: _contact_person,
				};
				_this.pageLoading = true;
				const { exists, error_message } = await ValidateContact("supplier", validatePayload);
				_this.pageLoading = false;
				if (exists) {
					if (error_message) {
						_this.$store.commit(SET_ERROR, [{ model: true, message: error_message }]);
					}
					return false;
				}
			}

			if (_this.stepper == 3) {
				const formErrors = _this.validateForm(_this.$refs.addressForm);
				if (formErrors.length) {
					_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
					return false;
				}

				if (!_this.$refs.addressForm.validate()) {
					return false;
				}
			}

			if (_this.stepper < 3) {
				_this.stepper++;
				return false;
			}

			try {
				_this.pageLoading = true;
				_this.billingAddress.address_type = 1;
				// this.shippingAddress.address_type = 2;
				let _Person_primary_count = 0;
				const _person = map(this.contact_person, (row) => {
					if (row.primary) {
						_Person_primary_count++;
					}
					if (_Person_primary_count > 1) {
						return { ...row, primary: 0 };
					}
					return { ...row, primary: row.primary ? 1 : 0 };
				});
				const _attachments = map(this.vendor.attachments, (row) => {
					return { ...row, file: null };
				});
				const formData = {
					..._this.vendor,
					barcode: null,
					billing_address: [_this.billingAddress],
					persons: _person,
					attachment: _attachments,
				};

				const { uuid } = await CreateOrUpdateVendor(formData);

				_this.$router.replace({
					name: "supplier-detail",
					params: { uuid },
					query: { t: new Date().getTime() },
				});

				_this.$store.commit(SET_MESSAGE, [
					{ model: true, message: "Success ! Supplier has been saved." },
				]);
				_this.$store.dispatch(SET_LOCAL_DB);
				// clearing local storage data
				this.clearLocalData();
			} catch (error) {
				_this.logError(error);
			} finally {
				_this.pageLoading = false;
			}
		},
		async updateCustomerDirect() {
			const _this = this;
			if (_this.stepper == 1) {
				const formErrors = _this.validateForm(_this.$refs.overviewForm);
				if (formErrors.length) {
					_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
					return false;
				}

				if (!_this.$refs.overviewForm.validate()) {
					return false;
				}
				const _vendor = _this.vendor;
				const formField = [
					{
						field: "company_name",
						value: _vendor.company_name,
					},
					{
						field: "display_name",
						value: _vendor.display_name,
					},
					{
						field: "uen",
						value: _vendor.uen,
					},
					{
						field: "phone_number",
						value: _vendor.phone_no,
					},
					{
						field: "email",
						value: _vendor.email,
					},
				];
				const validatePayload = {
					id: _vendor.id,
					filter_type: "contact",
					fields: formField,
					data: null,
				};
				_this.pageLoading = true;
				const { exists, error_message } = await ValidateContact("supplier", validatePayload);
				_this.pageLoading = false;
				if (exists) {
					if (error_message) {
						_this.$store.commit(SET_ERROR, [{ model: true, message: error_message }]);
					}
					return false;
				}
			}

			if (_this.stepper == 2) {
				if (_this.contact_person.length == 0) {
					_this.$store.commit(SET_ERROR, [
						{ model: true, message: "Please Add Atleast One Contact Person." },
					]);
					return false;
				}

				const formErrors = _this.validateForm(_this.$refs.personForm);
				if (formErrors.length) {
					_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
					return false;
				}

				if (!_this.$refs.personForm.validate()) {
					return false;
				}
				const _vendor = _this.vendor;
				const _contact_person = map(this.contact_person, (row) => {
					return {
						id: row.id ? row.id : null,
						fields: [
							{
								field: "did",
								value: row.did,
							},
							{
								field: "display_name",
								value: row.display_name,
							},
							{
								field: "phone_number",
								value: row.mobile,
							},
							{
								field: "email",
								value: row.email,
							},
						],
					};
				});
				const validatePayload = {
					id: _vendor.id,
					filter_type: "contact_person",
					fields: null,
					data: _contact_person,
				};
				_this.pageLoading = true;
				const { exists, error_message } = await ValidateContact("supplier", validatePayload);
				_this.pageLoading = false;
				if (exists) {
					if (error_message) {
						_this.$store.commit(SET_ERROR, [{ model: true, message: error_message }]);
					}
					return false;
				}
			}

			if (_this.stepper == 3) {
				const formErrors = _this.validateForm(_this.$refs.addressForm);
				if (formErrors.length) {
					_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
					return false;
				}

				if (!_this.$refs.addressForm.validate()) {
					return false;
				}
			}
			_this.pageLoading = true;
			try {
				_this.pageLoading = true;
				_this.billingAddress.address_type = 1;
				// this.shippingAddress.address_type = 2;
				let _Person_primary_count = 0;
				const _person = map(_this.contact_person, (row) => {
					if (row.primary) {
						_Person_primary_count++;
					}
					if (_Person_primary_count > 1) {
						return { ...row, primary: 0 };
					}
					return { ...row, primary: row.primary ? 1 : 0 };
				});
				const _attachments = map(_this.vendor.attachments, (row) => {
					return { ...row, file: null };
				});
				const formData = {
					..._this.vendor,
					billing_address: [_this.billingAddress],
					persons: _person,
					attachment: _attachments,
				};

				const { uuid } = await CreateOrUpdateVendor(formData);

				_this.$router.replace({
					name: "supplier-detail",
					params: { uuid },
					query: { t: new Date().getTime() },
				});

				_this.$store.commit(SET_MESSAGE, [
					{ model: true, message: "Success ! Supplier has been saved." },
				]);
				_this.$store.dispatch(SET_LOCAL_DB);
				// clearing local storage data
				this.clearLocalData();
			} catch (error) {
				_this.logError(error);
			} finally {
				_this.pageLoading = false;
			}
		},
		initVendor({ customer, category, contact_person, address }) {
			const _this = this;
			_this.barcode = customer.barcode;
			_this.$store.dispatch(SET_BREADCRUMB, [
				{ text: "Supplier", disabled: true },
				{ text: "Update", disabled: true },
				{ text: _this.barcode, disabled: true },
			]);
			_this.vendor = {
				id: customer.id,
				uuid: customer.uuid,
				barcode: customer.barcode,
				salutation: customer.title,
				first_name: customer.first_name,
				last_name: customer.last_name,
				uen: customer.uen,
				company_name: customer.company_name,
				image: customer.image,
				display_name: customer.display_name,
				email: customer.email,
				phone_no: customer.phone,
				fax: customer.fax,
				website: customer.website,
				remark: customer.remarks,
				business_description: customer.business_description,
				category: category && category.id ? category.id : null,
			};

			(this.billingAddress = {
				id: address[0].id,
				uuid: address[0].uuid,
				name: null,
				location: address[0].location,
				address_line_1: address[0].address_line_1,
				address_line_2: address[0].address_line_2,
				unit_number: address[0].unit_number,
				address_country: address[0].address_country,
				address_postal_code: address[0].address_postal_code,
				description: address[0].description,
			}),
				/* _this.shippingAddress = contact.shipping_address;
			_this.billingAddress = contact.billing_address; */

				(_this.contact_person = []);
			for (let i = 0; i < contact_person.length; i++) {
				_this.contact_person.push({
					id: contact_person[i].id,
					uuid: contact_person[i].uuid,
					salutation: contact_person[i].title,
					first_name: contact_person[i].first_name,
					last_name: contact_person[i].last_name,
					display_name: contact_person[i].display_name,
					email: contact_person[i].email,
					mobile: contact_person[i].phone,
					did: contact_person[i].did,
					primary: contact_person[i].primary ? 1 : 0,
					designation: contact_person[i].designation,
				});
			}

			if (!_this.contact_person.length) {
				_this.contact_person = [
					{
						id: null,
						uuid: null,
						salutation: "mr",
						first_name: null,
						last_name: null,
						display_name: null,
						email: null,
						mobile: null,
					},
				];
			}
		},
		getVendor() {
			const _this = this;
			GetVendor(_this.uuid)
				.then((data) => {
					_this.initVendor(data);
				})
				.catch((error) => {
					_this.goBack();
					_this.logError(error);
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		getCloneVendor() {
			const _this = this;
			GetVendor(_this.cloneUUID)
				.then((data) => {
					if (ObjectPath.has(data, "contact.id")) {
						ObjectPath.set(data, "contact.id", null);
						ObjectPath.set(data, "contact.uuid", null);
						if (ObjectPath.has(data, "contact.billing_address.id")) {
							ObjectPath.set(data, "contact.billing_address.id", null);
							ObjectPath.set(data, "contact.billing_address.uuid", null);
							ObjectPath.set(data, "contact.billing_address.contact", null);
						}
						if (ObjectPath.has(data, "contact.shipping_address.id")) {
							ObjectPath.set(data, "contact.shipping_address.id", null);
							ObjectPath.set(data, "contact.shipping_address.uuid", null);
							ObjectPath.set(data, "contact.shipping_address.contact", null);
						}
					}
					const contact_person = ObjectPath.get(data, "contact_person", []);
					for (let i = 0; i < contact_person.length; i++) {
						if (ObjectPath.has(data, `contact_person.${i}.id`)) {
							ObjectPath.set(data, `contact_person.${i}.id`, null);
							ObjectPath.set(data, `contact_person.${i}.uuid`, null);
							ObjectPath.set(data, `contact_person.${i}.contact`, null);
						}
					}

					_this.$nextTick(() => {
						_this.initVendor(data);
						_this.vendor.designation = ObjectPath.get(data, "contact_person.0.designation", null);
					});
				})
				.catch((error) => {
					_this.goBack();
					_this.logError(error);
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		pageTitle() {
			if (this.uuid) {
				return "Update Supplier";
			}
			return "Create new Supplier";
		},
		updateContactPerson(data) {
			this.contact_person = data;
		},
		validateCompanyName() {
			const _this = this;
			return new Promise((resolve, reject) => {
				_this.pageLoading = true;
				_this.companyNameError = null;
				ValidateCompanyName("supplier/company-name", {
					"company-name": _this.vendor.company_name,
					"customer-id": _this.vendor.id,
				})
					.then((output) => {
						if (output.exists) {
							_this.companyNameError = `Company name ${_this.vendor.company_name} already exists`;
							resolve(true);
						} else {
							resolve(false);
						}
					})
					.catch((error) => {
						reject(error);
					})
					.finally(() => {
						_this.pageLoading = false;
					});
			});
		},
		clearLocalData() {
			deleteData("create_supplier");
			deleteData("create_billing_address_supplier");
			deleteData("create_contact_person");
		},
		savetoLocal() {
			if (!this.uuid) {
				const create_supplier = {
					salutation: this.vendor.salutation,
					first_name: this.vendor.first_name,
					last_name: this.vendor.last_name,
					barcode: this.vendor.barcode,
					company_name: this.vendor.company_name,
					display_name: this.vendor.display_name,
					business_description: this.vendor.business_description,
					category: this.vendor.category,
					uen: this.vendor.uen,
					email: this.vendor.email,
					phone_no: this.vendor.phone_no,
				};
				saveData("create_supplier", create_supplier);
			}
		},
		saveBillingAddressLocal() {
			const create_billing_address_supplier = {
				location: this.billingAddress.location,
				address_line_1: this.billingAddress.address_line_1,
				address_line_2: this.billingAddress.address_line_2,
				unit_number: this.billingAddress.unit_number,
				address_postal_code: this.billingAddress.address_postal_code,
				address_country: this.billingAddress.address_country,
			};
			saveData("create_billing_address_supplier", create_billing_address_supplier);
		},
		loadLocalData() {
			let localSaveSupplier = getData("create_supplier");
			if (localSaveSupplier) {
				this.vendor.salutation = localSaveSupplier.salutation;
				this.vendor.first_name = localSaveSupplier.first_name;
				this.vendor.last_name = localSaveSupplier.last_name;
				this.vendor.barcode = localSaveSupplier.barcode;
				this.vendor.display_name = localSaveSupplier.display_name;
				this.vendor.business_description = localSaveSupplier.business_description;
				this.vendor.category = localSaveSupplier.category;
				this.vendor.uen = localSaveSupplier.uen;
				this.vendor.company_name = localSaveSupplier.company_name;
				this.vendor.email = localSaveSupplier.email;
				this.vendor.phone_no = localSaveSupplier.phone_no;
			}
			let localBillingAddress = getData("create_billing_address_supplier");
			if (localBillingAddress) {
				this.billingAddress.location = localBillingAddress.location;
				this.billingAddress.address_line_1 = localBillingAddress.address_line_1;
				this.billingAddress.address_line_2 = localBillingAddress.address_line_2;
				this.billingAddress.unit_number = localBillingAddress.unit_number;
				this.billingAddress.address_postal_code = localBillingAddress.address_postal_code;
				this.billingAddress.business_description = localBillingAddress.business_description;
				this.billingAddress.address_country = localBillingAddress.address_country;
			}
		},
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
		category_list() {
			return this.localDB("categories", []);
		},
	},
	mounted() {
		this.categoryList = this.localDB("categories", []);
		this.designationLists = this.localDB("designation", []);
		this.vendor.designation = this.designationLists[0].text;
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Supplier", disabled: true },
			{ text: "Create", disabled: true },
		]);
		// load form data from local storage
		this.loadLocalData();
		const { name, params } = this.$route;
		if (name === "supplier-update") {
			this.uuid = params.uuid;
			if (!this.uuid) {
				this.goBack();
			}
			this.pageLoading = true;
			this.getVendor();
		} else {
			this.genrateBarcode("supplier").then((output) => {
				this.vendor.barcode = output;
			});
		}

		if (this.uuid) {
			this.updateSupplier = true;
		}

		this.getDesignations();

		this.cloneUUID = ObjectPath.get(this.$route, "query.clone");

		if (this.cloneUUID) {
			this.getCloneVendor();
		}
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
